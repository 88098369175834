import React from 'react'
import BackgroundSlider from '../../Components/BackgroundSlider/BackgroundSlider'
import EventProgrames from '../../Components/EventProgrames/EventProgrames'
import FirstBanner from '../../Components/first-banner/First-banner'

const Home = () => {
  return (
    <>
    <FirstBanner/>
    <BackgroundSlider/>
    <EventProgrames/>
    </>
  )
}

export default Home
